import './scroll.css';

function LeftSection({ isTransitionVisible, children, ...props }) {
	return (
		<section
			className={`md:basis-[100%] h-screen ${
				isTransitionVisible ? 'overflow-hidden' : 'overflow-y-scroll '
			}`}
			{...props}
		>
			{children}
		</section>
	);
}

export default LeftSection;
