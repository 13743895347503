import React from 'react';

function TermsConditions() {
	return (
		<div className='md:section p-5'>
			<div className='mx-auto pt-16 md:pt-0 md:mt-10'>
				<h1 className='text-4xl font-medium'>Terms and Conditions</h1>
				<p>Effective Date: 05 June 2024</p>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Intorduction</h2>
				<p>
					Welcome to lovephoriainvitation.com (the "Service") operated by Lovephoria Invitation ("us",
					"we", or "our"). By accessing or using our Service, you agree to be bound by these Terms and
					Conditions ("Terms"). If you disagree with any part of the terms, you may not access the
					Service.
				</p>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Account</h2>
				<p>
					When you create an account with us, you must provide accurate and complete information. You are
					responsible for maintaining the confidentiality of your account and password and for
					restricting access to your computer. You agree to accept responsibility for all activities that
					occur under your account or password.
				</p>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Content</h2>
				<p>
					Our Service allows you to post, link, store, share, and otherwise make available certain
					information, text, graphics, videos, or other material ("Content"). You are responsible for the
					Content that you post on or through the Service, including its legality, reliability, and
					appropriateness.
				</p>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Prohibited Uses</h2>
				<p>
					You may use the Service only for lawful purposes and in accordance with the Terms. You agree
					not to use the Service:
				</p>
				<ul className='ml-10 list-disc'>
					<li>In any way that violates any applicable national or international law or regulation.</li>
					<li>
						For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way.
					</li>
					<li>
						To transmit, or procure the sending of, any advertising or promotional material without our
						prior consent.
					</li>
				</ul>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Termination</h2>
				<p>
					We may terminate or suspend your account immediately, without prior notice or liability, for
					any reason whatsoever, including without limitation if you breach the Terms.
				</p>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Limitation of Liability</h2>
				<p>
					In no event shall Lovephoria Invitation, nor its directors, employees, partners, agents,
					suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or
					punitive damages, including without limitation, loss of profits, data, use, goodwill, or other
					intangible losses, resulting from (i) your use or inability to use the Service; (ii) any
					unauthorized access to or use of our servers and/or any personal information stored therein;
					(iii) any interruption or cessation of transmission to or from the Service; and (iv) any bugs,
					viruses, or the like that may be transmitted to or through our Service by any third party.
				</p>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Governing Law</h2>
				<p>
					These Terms shall be governed and construed in accordance with the laws of Indonesia, without
					regard to its conflict of law provisions.
				</p>
			</div>
		</div>
	);
}

export default TermsConditions;
