import React from 'react';

function CardRfvp({ message, invitedPeople, date, ...props }) {
	return (
		<div
			className='my-1 rounded-xl p-3 w-full text-white text-start bg-[#3A241480] '
			{...props}
		>
			<p className='font-bold'>{invitedPeople}</p>
			<p className='px-2 font-light text-sm'>{date || `2 bulan yang lalu`}</p>
			<p className='font-bold'>{message}</p>
		</div>
	);
}

export default CardRfvp;
