const numberToWords = (num) => {
	const words = [
		'One',
		'Two',
		'Three',
		'Four',
		'Five',
		'Six',
		'Seven',
		'Eight',
		'Nine',
		'Ten',
		'Eleven',
		'Twelve',
		'Thirteen',
		'Fourteen',
		'Fifteen',
		'Sixteen',
		'Seventeen',
		'Eighteen',
		'Nineteen',
		'Twenty',
		'Twenty-One',
		'Twenty-Two',
	];
	return words[num] || num.toString();
};

const formatDate = (unixTimestamp, format = '/') => {
	const date = new Date(unixTimestamp); // Convert Unix timestamp to milliseconds
	const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	const day = days[date.getUTCDay()];
	const dayNumber = date.getUTCDate().toString().padStart(2, '0');
	const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
	const monthName = months[date.getUTCMonth()];
	const monthNumber = date.getUTCMonth();
	const monthWord = numberToWords(monthNumber);
	const year = date.getUTCFullYear();

	if (format === '/') {
		return `${day} / ${dayNumber} / ${month} / ${year}`;
	} else if (format === ',') {
		return `${day}, ${dayNumber} ${monthName} ${year}`;
	} else {
		return {
			day,
			date: monthWord,
			year,
		};
	}
};

const timeAgo = (date) => {
	const now = new Date();
	const past = new Date(date);
	const diff = now - past;

	const seconds = Math.floor(diff / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const months = Math.floor(days / 30);
	const years = Math.floor(months / 12);

	if (years > 0) {
		return `${years} ${years === 1 ? 'year' : 'years'} ago`;
	} else if (months > 0) {
		return `${months} ${months === 1 ? 'month' : 'months'} ago`;
	} else if (days > 0) {
		return `${days} ${days === 1 ? 'day' : 'days'} ago`;
	} else if (hours > 0) {
		return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
	} else if (minutes > 0) {
		return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
	} else {
		return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
	}
};

const parseTimeString = (dateString, timeString) => {
	const [startTime, endTime] = timeString.split(' - ');
	const startDate = new Date(`${dateString}T${convertTo24HourFormat(startTime)}Z`);
	const endDate = new Date(`${dateString}T${convertTo24HourFormat(endTime)}Z`);
	return { startDate, endDate };
};

const convertTo24HourFormat = (time12h) => {
	const [time, modifier] = time12h.split(' ');
	let [hours, minutes] = time.split(':');
	if (hours === '12') {
		hours = '00';
	}

	if (modifier === 'PM') {
		hours = parseInt(hours, 10) + 12;
	}
	return `${hours}:${minutes}:00`;
};

export { formatDate, timeAgo, parseTimeString };
