import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import Btn from '../../components/button/btn';
import CardProject from '../../components/card/cardPriceHome';
import { GlobalContext } from '../../config/context/global';

const BASIC = [
	{ name: 'Unlimited Share', active: true },
	{ name: 'Aktif 3 Bulan', active: true },
	{ name: 'Cover Undangan', active: true },
	{ name: 'Profil Mempelai', active: true },
	{ name: 'Informasi Acara', active: true },
	{ name: 'Countdown', active: true },
	{ name: 'Google Maps', active: true },
	{ name: 'Quotes', active: true },
	{ name: 'Wedding Wish & RSVP', active: true },
	{ name: 'Autoplay Backsound', active: true },
	{ name: '8 Galeri Foto', active: true },
	{ name: 'Revisi 3x', active: true },
	{ name: 'RSVP Link to Google Sheets', active: false },
	{ name: 'Dompet Digital', active: false },
	{ name: 'Dresscodes', active: false },
	{ name: 'Love Story', active: false },
	{ name: 'Intro', active: false },
	{ name: 'Video Prewedding', active: false },
];

const PREMIUM = [
	{ name: 'Unlimited Share', active: true },
	{ name: 'Aktif 6 Bulan', active: true },
	{ name: 'Cover Undangan', active: true },
	{ name: 'Profil Mempelai', active: true },
	{ name: 'Informasi Acara', active: true },
	{ name: 'Countdown', active: true },
	{ name: 'Google Maps', active: true },
	{ name: 'Quotes', active: true },
	{ name: 'Wedding Wish & RSVP', active: true },
	{ name: 'Autoplay Backsound', active: true },
	{ name: 'Galeri Foto 10 Foto', active: true },
	{ name: 'Revisi 3x', active: true },
	{ name: 'RSVP Link to Google Sheets', active: true },
	{ name: 'Dompet Digital', active: true },
	{ name: 'Dresscode', active: true },
	{ name: 'Love Story', active: true },
	{ name: 'Intro', active: false },
	{ name: 'Video Prewedding', active: false },
];

const GOLD = [
	{ name: 'Unlimited Share', active: true },
	{ name: 'Aktif 1 Tahun', active: true },
	{ name: 'Cover Undangan', active: true },
	{ name: 'Profil Mempelai', active: true },
	{ name: 'Informasi Acara', active: true },
	{ name: 'Countdown', active: true },
	{ name: 'Google Maps', active: true },
	{ name: 'Quotes', active: true },
	{ name: 'Wedding Wish & RSVP', active: true },
	{ name: 'Autoplay Backsound', active: true },
	{ name: 'Galeri Foto 20 Foto', active: true },
	{ name: 'Revisi 3x', active: true },
	{ name: 'RSVP Link to Google Sheets', active: true },
	{ name: 'Dompet Digital', active: true },
	{ name: 'Dresscode', active: true },
	{ name: 'Love Story', active: true },
	{ name: 'Intro', active: true },
	{ name: 'Video Prewedding', active: true },
];

function Price() {
	const navigate = useNavigate();
	const { globalAct } = useContext(GlobalContext);
	return (
		<div className='section text-[#3A2414] flex flex-col gap-[8vh] '>
			<div className='flex flex-col w-fit mx-auto'>
				<h2 className='text-4xl font-bold my-3'>Paket Harga</h2>
				{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> */}
			</div>
			<div className='flex flex-col gap-[5vh]'>
				<div className='mx-auto md:mx-0 md:flex md:flex-col lg:flex-row justify-center gap-8 '>
					<CardProject
						title={'BASIC'}
						description={'lorem ipsum dolor sit amet'}
						features={BASIC}
						price={'199.000'}
					/>
					<CardProject
						title={'PREMIUM'}
						description={'lorem ipsum dolor sit amet'}
						features={PREMIUM}
						price={'299.000'}
					/>
					<CardProject
						title={'GOLD'}
						description={'lorem ipsum dolor sit amet'}
						features={GOLD}
						price={'359.000'}
					/>
				</div>
				<div className='mb-6'>
					<Btn
						colorBg={'bg-[#B07C92]'}
						title={'Pilih Paket'}
						colorText={'text-white'}
						className={'px-5 py-3'}
						onClick={() => {
							globalAct.setPaket(null);
							navigate('/hubungi-kami');
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default Price;
