import { useState, createContext } from 'react';

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
	const [subscriptionData, setSubscriptionData] = useState();

	const contextValue = {
		subscriptionData,
		setSubscriptionData,
	};

	return (
		<SubscriptionContext.Provider value={contextValue}>{children}</SubscriptionContext.Provider>
	);
};
