import { useContext } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa'; // Import the check icon from Font Awesome
import Btn from '../button/btn';
import { GlobalContext } from '../../config/context/global';
import { useNavigate } from 'react-router-dom';

function CardPriceHome({ title, description, features, price }) {
	const navigate = useNavigate();
	const { globalAct } = useContext(GlobalContext);
	const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
	return (
		<div className='border my-5 md:my-0 rounded-lg border-[#3A2414] w-[320px] md:w-2/3 md:mx-auto p-5 text-start'>
			<div className=''>
				<h3 className='font-bold text-xl'>{title}</h3>
				{/* <p>{description}</p> */}
			</div>
			<hr className='bg-[#3A2414] h-0.5 my-3' />

			<div className='flex flex-col gap-8'>
				<div className=' flex flex-col gap-2'>
					<h4 className='text-4xl font-black'>
						Rp. {price}
						{/* <span className='text-3xl'>/mon</span> */}
					</h4>
					{/* <p className='font-normal text-lg '>or ${priceYear} yearly</p> */}
				</div>
				<Btn
					title={`Pilih Paket ${title}`}
					colorBg={'bg-[#B07C92]'}
					colorText={'text-white'}
					className={'w-full py-2'}
					onClick={() => {
						globalAct.setPaket(titleCapitalized);
						navigate('/hubungi-kami');
					}}
				/>
			</div>
			<hr className='bg-[#3A2414] h-0.5 my-5' />

			<ul className='font-normal '>
				{features.map((feature, index) => {
					return (
						<li
							key={index}
							className='flex gap-3 my-2'
						>
							{feature.active ? (
								<FaCheck
									style={{
										color: 'white',
										padding: '2.5px',
										backgroundColor: 'green',
										borderRadius: '100%',
										width: '20px',
										height: '20px',
									}}
								/>
							) : (
								<FaTimes
									style={{
										color: 'white',
										padding: '2.5px',
										backgroundColor: '#b23b26',
										borderRadius: '100%',
										width: '20px',
										height: '20px',
									}}
								/>
							)}

							{feature.name}
						</li>
					);
				})}
			</ul>
		</div>
	);
}

export default CardPriceHome;
