import React, { useContext } from 'react';
import Btn from '../button/btn';
import { MarriageContext } from '../../config/context/marriagedCard';
import { formatDate } from '../../utils/formatDate';

function Location() {
	const { marriageCardData } = useContext(MarriageContext);
	const { section_agenda } = marriageCardData;

	return (
		<div
			className='w-full h-screen'
			style={{
				backgroundColor: section_agenda.agendaSectionbackgroundColor,
				backgroundImage: section_agenda.isAgendaSectionUsingImage
					? `url(${section_agenda.agendaSectionBackgroundImage})`
					: 'none',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				scrollSnapAlign: 'center',
			}}
		>
			<div className='relative h-screen p-10 md:px-20 text-white text-start flex flex-col md:justify-center '>
				<div
					className='text-4xl md:text-6xl font-bold w-1/2'
					style={{ color: section_agenda.agendaSectionTitleColor }}
				>
					<h1>{section_agenda.agendaSectionTitleText}</h1>
				</div>
				<h1 className='md:text-center text-lg md:text-4xl font-semibold my-5'>
					{formatDate(marriageCardData.unixWeddingDate, ',')}
				</h1>
				<div className='hidden md:grid grid-cols-4 gap-4 text-center my-5 '>
					<div>
						<p className='text-xl'>{section_agenda.agendaSectionAcaraPertama}</p>
					</div>
					<div>
						<p className='text-xl'>{section_agenda.agendaSectionHoursPertama}</p>
					</div>
					<div>
						<p className='text-xl'>{section_agenda.agendaSectionAcaraKedua}</p>
					</div>
					<div>
						<p className='text-xl'>{section_agenda.agendaSectionHoursKedua}</p>
					</div>
					<div className='grid grid-cols-subgrid gap-4 col-span-3'>
						<div className='col-start-2'>
							<hr className='w-[1px] h-16 bg-white mx-auto' />
						</div>
					</div>
					<div>
						<hr className='w-[1px] h-16 bg-white mx-auto' />
					</div>
					<div className=' mx-auto'>
						<p>
							{`${section_agenda.agendaSectionLocationPertama}, ${section_agenda.agendaSectionAddressPertama}`}
						</p>
					</div>
					<div>
						<Btn
							title={'LOCATION'}
							colorText={'text-white'}
							colorBg={'bg-transparent'}
							className={'my-2 py-0 px-5 border border-white rounded-full'}
							onClick={() => window.open(section_agenda.agendaSectionMapsPertama, '_blank')}
						/>
						<br />
					</div>
					<div className=' mx-auto'>
						<p>
							{`${section_agenda.agendaSectionLocationKedua}, ${section_agenda.agendaSectionAddressKedua}`}
						</p>
					</div>
					<div>
						<Btn
							title={'LOCATION'}
							colorText={'text-white'}
							colorBg={'bg-transparent'}
							className={'my-2 py-0 px-5 border border-white rounded-full'}
							onClick={() => window.open(section_agenda.agendaSectionMapsKedua, '_blank')}
						/>
						<br />
					</div>
				</div>
				<div className='md:hidden p-0 m-0 h-full'>
					<div className='flex h-2/5 gap-6'>
						<div className='w-1/2 flex flex-col items-start justify-between '>
							<h1 className='font-bold'>{section_agenda.agendaSectionAcaraPertama}</h1>
							<div className='text-sm'>
								<p className='font-bold text-lg'>{section_agenda.agendaSectionLocationPertama}</p>
								<span>{section_agenda.agendaSectionAddressPertama}</span>
							</div>
						</div>
						<div className='w-1/2  flex flex-col items-center '>
							<h1 className='font-bold'>{section_agenda.agendaSectionHoursPertama}</h1>
							<div className='w-0.5 bg-white h-20 mt-2'></div>
							<div className='text-center my-3'>
								<Btn
									title={'LOCATION'}
									colorText={'text-white'}
									colorBg={'bg-transparent'}
									className={'my-3 py-0 px-2 text-xs border border-white rounded-full'}
									onClick={() => window.open(section_agenda.agendaSectionMapsPertama, '_blank')}
								/>
								<br />
							</div>
						</div>
					</div>
					<hr className='h-0.5 bg-white w-full my-4' />
					<div className='flex h-2/5 gap-6'>
						<div className='w-1/2 flex flex-col items-start justify-between  '>
							<h1 className='font-bold'>{section_agenda.agendaSectionAcaraKedua}</h1>
							<div className='text-sm'>
								<p className='font-bold text-lg'>{section_agenda.agendaSectionLocationKedua}</p>
								<span>{section_agenda.agendaSectionAddressKedua}</span>
							</div>
						</div>
						<div className='w-1/2 flex flex-col items-center '>
							<h1 className='font-bold'>{section_agenda.agendaSectionHoursKedua}</h1>
							<div className='w-0.5 bg-white h-20 mt-2'></div>
							<div className='text-center my-3'>
								<Btn
									title={'LOCATION'}
									colorText={'text-white'}
									colorBg={'bg-transparent'}
									className={'my-3 py-0 px-2 text-xs border border-white rounded-full'}
									onClick={() => window.open(section_agenda.agendaSectionMapsKedua, '_blank')}
								/>
								<br />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Location;
