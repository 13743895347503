import {
	faCalendar,
	faEnvelope,
	faFont,
	faHandsPraying,
	faHeart,
	faImages,
	faMapLocationDot,
	faMobile,
	faMusic,
	faPaintBrush,
	faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
// import image from '../../assets/images/example.png';

function Fitur() {
	return (
		<div className='flex section relative '>
			<div className='grid grid-cols-1 px-3 md:grid-cols-2 lg:px-0 lg:grid-cols-3 gap-4 w-full'>
				<div className='w-full h-fit min-h-[250px] rounded-lg bg-[#444545] flex flex-col p-5'>
					<div className=' mb-5 text-white p-5 text-start text-3xl'>
						<FontAwesomeIcon icon={faPaintBrush} />
					</div>
					<div className='text-start text-[#E4DACF]'>
						<h4 className='text-2xl mx-3 my-2 font-bold'>Desain Elegan & Modern</h4>
						<p className='mx-3 text-md'>
							Undangan didesain dengan elegan & berkesan. Jumlah Tamu Tanpa Batasan.
						</p>
					</div>
				</div>
				<div className='w-full h-fit min-h-[250px] rounded-lg bg-[#62482B] flex flex-col p-5'>
					<div className=' mb-5 text-white p-5 text-start text-3xl'>
						<FontAwesomeIcon icon={faPeopleGroup} />
					</div>
					<div className='text-start text-[#E4DACF] '>
						<h4 className='text-2xl mx-3 my-2 font-bold'>Jumlah Tamu Tanpa Batasan</h4>
						<p className='mx-3 text-md'>Tidak ada biaya tambahan untuk berapapun tamu yang diundang.</p>
					</div>
				</div>
				<div className='w-full h-fit min-h-[250px] rounded-lg bg-[#444545] flex flex-col p-5'>
					<div className=' mb-5 text-white p-5 text-start text-3xl ]'>
						<FontAwesomeIcon icon={faFont} />
					</div>
					<div className='text-start text-[#E4DACF]'>
						<h4 className='text-2xl mx-3 my-2 font-bold'>Custom Nama Tamu</h4>
						<p className='mx-3 text-md'>
							Sertakan nama teman / saudara / keluarga di undanganmu agar lebih eksklusif.
						</p>
					</div>
				</div>
				<div className='w-full h-fit min-h-[250px] rounded-lg bg-[#62482B] flex flex-col p-5'>
					<div className=' mb-5 text-white p-5 text-start text-3xl'>
						<FontAwesomeIcon icon={faMobile} />
					</div>
					<div className='text-start text-[#E4DACF] '>
						<h4 className='text-2xl mx-3 my-2 font-bold'>Akses Dimana Saja</h4>
						<p className='mx-3 text-md'>Akses undangan dari perangkat apapun dengan cepat.</p>
					</div>
				</div>{' '}
				<div className='w-full h-fit min-h-[250px] rounded-lg bg-[#444545] flex flex-col p-5'>
					<div className=' mb-5 text-white p-5 text-start text-3xl ]'>
						<FontAwesomeIcon icon={faCalendar} />
					</div>
					<div className='text-start text-[#E4DACF]'>
						<h4 className='text-2xl mx-3 my-2 font-bold'>RSVP</h4>
						<p className='mx-3 text-md'>Estimasi jumlah kehadiran dari orang-orang yang kamu sayangi.</p>
					</div>
				</div>
				<div className='w-full h-fit min-h-[250px] rounded-lg bg-[#62482B] flex flex-col p-5'>
					<div className=' mb-5 text-white p-5 text-start text-3xl'>
						<FontAwesomeIcon icon={faEnvelope} />
					</div>
					<div className='text-start text-[#E4DACF] '>
						<h4 className='text-2xl mx-3 my-2 font-bold'>Amplop Digital</h4>
						<p className='mx-3 text-md'>
							Terima amplop / hadiah dari tamu secara digital via transfer atau e-money.
						</p>
					</div>
				</div>
				<div className='w-full h-fit min-h-[250px] rounded-lg bg-[#444545] flex flex-col p-5'>
					<div className=' mb-5 text-white p-5 text-start text-3xl'>
						<FontAwesomeIcon icon={faHandsPraying} />
					</div>
					<div className='text-start text-[#E4DACF] '>
						<h4 className='text-2xl mx-3 my-2 font-bold'>Wedding Wishes</h4>
						<p className='mx-3 text-md'>
							Biarkan undangan untuk membagikan kesan & pesan ataupun doa atas hari bahagiamu.
						</p>
					</div>
				</div>
				<div className='w-full h-fit min-h-[250px] rounded-lg bg-[#62482B] flex flex-col p-5'>
					<div className=' mb-5 text-white p-5 text-start text-3xl'>
						<FontAwesomeIcon icon={faMapLocationDot} />
					</div>
					<div className='text-start text-[#E4DACF] '>
						<h4 className='text-2xl mx-3 my-2 font-bold'>Navigasi Lokasi</h4>
						<p className='mx-3 text-md'>Permudah tamu undangan untuk menemukan lokasi acaramu.</p>
					</div>
				</div>
				<div className='w-full h-fit min-h-[250px] rounded-lg bg-[#444545] flex flex-col p-5'>
					<div className=' mb-5 text-white p-5 text-start text-3xl'>
						<FontAwesomeIcon icon={faMusic} />
					</div>
					<div className='text-start text-[#E4DACF] '>
						<h4 className='text-2xl mx-3 my-2 font-bold'>Background Music</h4>
						<p className='mx-3 text-md'>
							Perkuat kesan dari acara spesialmu dengan musik pilihanmu di undanganmu.
						</p>
					</div>
				</div>
				<div className='w-full h-fit min-h-[250px] rounded-lg bg-[#62482B] flex flex-col p-5'>
					<div className=' mb-5 text-white p-5 text-start text-3xl'>
						<FontAwesomeIcon icon={faImages} />
					</div>
					<div className='text-start text-[#E4DACF] '>
						<h4 className='text-2xl mx-3 my-2 font-bold'>Galeri Foto</h4>
						<p className='mx-3 text-md'>
							Bagikan foto/video momen spesialmu dengan pasangan kepada para undangan.
						</p>
					</div>
				</div>
				<div className='w-full h-fit min-h-[250px] rounded-lg bg-[#444545] flex flex-col p-5'>
					<div className=' mb-5 text-white p-5 text-start text-3xl'>
						<FontAwesomeIcon icon={faHeart} />
					</div>
					<div className='text-start text-[#E4DACF] '>
						<h4 className='text-2xl mx-3 my-2 font-bold'>Love Stories</h4>
						<p className='mx-3 text-md'>Ceritakan kisah cintamu kepada para undangan.</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Fitur;
