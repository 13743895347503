import React, { useState, useContext, useEffect } from 'react';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DropdownInput from '../../components/dropdown/dropdownInputContact';
import Btn from '../../components/button/btn';
import { GlobalContext } from '../../config/context/global';
import { SubscriptionContext } from '../../config/context/subscription';
import { TemplateContext } from '../../config/context/template';
import { getAll, postData } from '../../api';
import Swal from 'sweetalert2';

function ContactUs() {
	const [checkedItems, setCheckedItems] = useState(false);
	const [countryCode, setCountryCode] = useState('ID');
	const { globalCtx } = useContext(GlobalContext);
	const { subscriptionData, setSubscriptionData } = useContext(SubscriptionContext);
	const { templateData, setTemplateData } = useContext(TemplateContext);
	const initalForm = {
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		subscriptionId: '',
		templateId: '',
		message: '',
	};
	const [formLead, setFormLead] = useState(initalForm);
	const handleOnChange = (value, country) => {
		setFormLead((prevForm) => ({
			...prevForm,
			phoneNumber: `+${value}`,
		}));
		setCountryCode(country.countryCode.toUpperCase());
	};
	const handleChangeInput = (e) => {
		try {
			const { name, value } = e.target;
			setFormLead((prevFormlead) => ({
				...prevFormlead,
				[name]: value,
			}));
		} catch (error) {
			console.error(error);
		}
	};
	// Handle checkbox change
	const handleCheckboxChange = (event) => {
		const { checked } = event.target;
		setCheckedItems(checked);
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await postData({ url: `lead/add`, data: formLead });
			setFormLead(initalForm);
			setCheckedItems(false);
			Swal.fire({
				title: 'Successfully',
				text:
					'Your message was sent successfully to team Lovephoria. We will get back to you soon. Thanks',
				icon: 'success',
			});
		} catch (error) {
			// console.log(error);
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: error?.response?.data?.message || 'An error occurred during form submission.',
				showConfirmButton: true,
			});
		}
	};
	const fetchSubscription = async () => {
		try {
			const { data: subscriptionData } = await getAll({ url: `subscription-plan/list/active` });
			setSubscriptionData(subscriptionData);
			setFormLead((prevForm) => ({
				...prevForm,
				subscriptionId: subscriptionData[0].id,
			}));
			// console.log(subscriptionData);
		} catch (error) {
			console.error('Error fetching subscription', error);
		}
	};
	const fetchTemplateCard = async () => {
		try {
			const { data: templateCarddata } = await getAll({ url: `template-card/list/active` });
			// console.log(templateCarddata);
			setTemplateData(templateCarddata);
			setFormLead((prevForm) => ({
				...prevForm,
				templateId: templateCarddata[0].id,
			}));
		} catch (error) {
			console.error('Error fetching template card');
		}
	};
	useEffect(() => {
		fetchSubscription();
		fetchTemplateCard();
	}, []);
	return (
		<div className='flex flex-col items-start gap-10 md:section md:py-28 py-24'>
			<div className='md:flex mx-auto w-full justify-between '>
				<div className='md:w-1/2 flex flex-col items-start justify-start text-[#624A2D] '>
					<div className='text-start px-5'>
						<h2 className='h2 font-bold text-[#3A2414]'>Hubungi kami</h2>
						<p className='py-8'>Tim kami yang ramah akan senang mendengar kabar Anda.</p>
					</div>

					<ul className='flex flex-col gap-5 px-5'>
						<li className='flex gap-3'>
							<FontAwesomeIcon
								icon={faEnvelope}
								className='text-[#B07C92] text-xl'
							/>
							<span className='-mt-0.5'>lovephoriaid@gmail.com</span>
						</li>
						<li className='flex gap-3'>
							<FontAwesomeIcon
								icon={faPhone}
								className='text-[#B07C92] text-xl'
							/>
							+62 821-4434-6975
						</li>
						<li className='flex gap-3'>
							<FontAwesomeIcon
								icon={faMapMarkerAlt}
								className='text-[#B07C92] text-xl'
							/>
							Denpasar, Bali
						</li>
					</ul>
				</div>
				<form
					onSubmit={handleSubmit}
					className='md:w-1/2 flex flex-col justify-center mt-10 md:mt-0 mx-5 md:mx-0'
				>
					<div className='grid grid-cols-2 gap-5 h-fit '>
						<label className='block'>
							<span className='block text-start text-sm font-medium '>Nama Depan</span>
							<input
								type='text'
								name='firstName'
								className='mt-1 px-5 py-3 bg-white border shadow-sm border-[#B07C92] placeholder-slate-400 focus:outline-none focus:border-[#B07C92] focus:ring-[#B07C92] block w-full sm:text-sm focus:ring-1'
								value={formLead.firstName}
								required
								onChange={handleChangeInput}
							/>
						</label>
						<label className='block'>
							<span className='block text-start text-sm font-medium '>Nama Belakang</span>
							<input
								type='text'
								name='lastName'
								required
								value={formLead.lastName}
								className='mt-1 px-5 py-3 bg-white border shadow-sm border-[#B07C92] placeholder-slate-400 focus:outline-none focus:border-[#B07C92] focus:ring-[#B07C92] block w-full sm:text-sm focus:ring-1'
								onChange={handleChangeInput}
							/>
						</label>
						<label className='block'>
							<span className='block text-start text-sm font-medium '>Email</span>
							<input
								type='text'
								name='email'
								value={formLead.email}
								required
								className='mt-1 px-5 py-3 bg-white border shadow-sm border-[#B07C92] placeholder-slate-400 focus:outline-none focus:border-[#B07C92] focus:ring-[#B07C92] block w-full sm:text-sm focus:ring-1'
								onChange={handleChangeInput}
							/>
						</label>
						<label className='block'>
							<span className='block text-start text-sm font-medium mb-1'>Nomor Wa</span>
							<PhoneInput
								country={countryCode}
								value={formLead.phoneNumber || '62'}
								onChange={handleOnChange}
								inputStyle={{
									width: '100%',
									padding: '23px 45px',
									border: '1px solid #B07C92',
									borderRadius: '4px',
								}}
								containerStyle={{
									width: '100%',
									display: 'flex',
									// padding: '0.50rem 0px',
								}}
								buttonStyle={{
									border: '1px solid #B07C92',
									borderRadius: '4px',
									marginRight: '8px',
								}}
								dropdownStyle={{
									borderRadius: '4px',
									textAlign: 'start',
								}}
							/>
						</label>
					</div>
					<DropdownInput
						title='Pilih Design'
						name='templateId'
						value={templateData || []}
						setForm={setFormLead}
						formLead={formLead}
						className='border border-[#B07C92] focus:border-[#B07C92] focus:ring-[#B07C92]'
					/>
					<DropdownInput
						title='Pilih Paket'
						name='subscriptionId'
						value={subscriptionData || []}
						formLead={formLead}
						setForm={setFormLead}
						defaultValue={globalCtx.paket}
						className='border border-[#B07C92] focus:border-[#B07C92] focus:ring-[#B07C92]'
					/>
					<div className='text-start my-2'>
						<p className='mb-2'>Pesan</p>
						<textarea
							id='message'
							name='message'
							rows='4'
							className='resize-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight border-[#B07C92] focus:outline-none focus:ring-1 focus:ring-[#B07C92]'
							placeholder='Type your message here...'
							value={formLead.message}
							onChange={handleChangeInput}
						></textarea>
					</div>
					<div className='flex items-center my-4'>
						<input
							type='checkbox'
							id='checkbox1'
							name='checkbox1'
							checked={checkedItems || false}
							onChange={handleCheckboxChange}
							style={{ appearance: 'auto' }}
							className='h-5 w-5 text-[#B07C92] focus:ring-[#B07C92] border-2 border-[#B07C92] rounded-full'
						/>
						<label
							htmlFor='checkbox1'
							className='ml-2 text-'
						>
							Saya menerima{' '}
							<a
								href='/term-conditions'
								className='text-[#B07C92] underline'
							>
								Syarat dan Ketentuan
							</a>
						</label>
					</div>
					<Btn
						title={'Submit'}
						colorBg={'bg-[#B07C92]'}
						colorText={'text-white'}
						className={'w-1/4 py-2'}
						disabled={!checkedItems}
						type='submit'
					/>
				</form>
			</div>
		</div>
	);
}

export default ContactUs;
