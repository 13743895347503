import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// import Btn from './button/btnCustom'; // Sesuaikan dengan path yang sesuai

const WhatsappButton = ({ phoneNumber, message }) => {
	const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

	return (
		// <div className='w-52 h-52 rounded-full bg-green-400'>
		<button
			onClick={() => window.open(whatsappUrl)}
			className='bg-green-600 w-16 h-16 md:w-20 md:h-20 lg:w-20 lg:h-20 fixed  right-7 md:right-10 bottom-7 flex items-center justify-center  space-x-2 py-2 px-4 rounded-full'
		>
			<FontAwesomeIcon
				icon={faWhatsapp}
				className='text-4xl md:text-5xl'
				style={{ color: 'white' }}
			/>
		</button>
		//</div>
	);
};

export default WhatsappButton;
