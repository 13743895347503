import React from 'react';

function Policy() {
	return (
		<div className='md:section p-5'>
			<div className='mx-auto pt-16 md:pt-0 md:mt-10'>
				<h1 className='text-4xl font-medium'>Privacy Policy</h1>
				<p>Effective Date: 05 June 2024</p>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Intorduction</h2>
				<p>
					Welcome to Lovephoria Invitation ("Company", "we", "our", "us")! We are committed to protecting
					your personal information and your right to privacy. If you have any questions or concerns
					about this privacy notice or our practices with regard to your personal information, please
					contact us at [Contact Information].
				</p>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Information We Collect</h2>
				<p>
					We collect personal information that you voluntarily provide to us when you register on the
					website, express an interest in obtaining information about us or our products and services,
					when you participate in activities on the website, or otherwise when you contact us.
				</p>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>How We Use Your Information</h2>
				<p>
					We use personal information collected via our website for a variety of business purposes
					described below. We process your personal information for these purposes in reliance on our
					legitimate business interests, in order to enter into or perform a contract with you, with your
					consent, and/or for compliance with our legal obligations.
				</p>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Sharing Your Information</h2>
				<p>
					We only share information with your consent, to comply with laws, to protect your rights, or to
					fulfill business obligations. We may process or share your data based on the following legal
					basis:
				</p>
				<ul className='ml-10 list-disc'>
					<li>
						<b>Consent:</b> We may process your data if you have given us specific consent to use your
						personal information in a specific purpose.
					</li>
					<li>
						<b>Performance of a Contract:</b>
						Where we have entered into a contract with you, we may process your personal information to
						fulfill the terms of our contract.
					</li>
					<li>
						<b>Legal Obligations:</b>
						We may disclose your information where we are legally required to do so in order to comply
						with applicable law, governmental requests, a judicial proceeding, court order, or legal
						process, such as in response to a court order or a subpoena.
					</li>
				</ul>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Data Security</h2>
				<p>
					We aim to protect your personal information through a system of organizational and technical
					security measures. We have implemented appropriate technical and organizational security
					measures designed to protect the security of any personal information we process. However,
					please also remember that we cannot guarantee that the internet itself is 100% secure.
				</p>
			</div>
			<div className='text-start my-5 text-lg'>
				<h2 className='text-2xl font-medium'>Your Privacy Rights</h2>
				<p>
					In some regions (like the European Economic Area), you have certain rights under applicable
					data protection laws. These may include the right (i) to request access and obtain a copy of
					your personal information, (ii) to request rectification or erasure, (iii) to restrict the
					processing of your personal information, and (iv) if applicable, to data portability.
				</p>
			</div>
		</div>
	);
}

export default Policy;
