import { useParams, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../config/context/global';
import { SubscriptionContext } from '../config/context/subscription';
import { TemplateContext } from '../config/context/template';
import { MarriageContext } from '../config/context/marriagedCard';
// import { getById } from '../api';
import datas from '../data/marriage_all.json';

// layout
import TemplateOne from '../layout/template/cardOne';
import TemplateTwo from '../layout/template/cardTwo';
import TemplateThree from '../layout/template/cardThree';

const template1 = process.env.REACT_APP_TEMPLATE_ONE_ID;
const template2 = process.env.REACT_APP_TEMPLATE_TWO_ID;
const template3 = process.env.REACT_APP_TEMPLATE_THREE_ID;

function Invite() {
	const { user, invitedUser } = useParams();

	const navigate = useNavigate();
	const { globalAct } = useContext(GlobalContext);
	const { setSubscriptionData } = useContext(SubscriptionContext);
	const { setTemplateData } = useContext(TemplateContext);
	const { setMarriageCardData } = useContext(MarriageContext);
	const [userData, setUserData] = useState();
	const fetchDatas = () => {
		const userData =
			datas.find((marriageCard) => marriageCard.marriageCard.master_user.username === user) || null;
		const { marriageCard, peopleInvited } = userData;
		setUserData(marriageCard);
		// console.log(marriageCard);
		globalAct.setInvitedPeople(invitedUser);
		globalAct.setBrideName(marriageCard.section_bride.brideSectionBrideName);
		globalAct.setGroomName(marriageCard.section_groom.groomSectionGroomName);
		globalAct.setPeopleInvitedData(peopleInvited);
		setSubscriptionData(marriageCard.master_subscription_plan);
		setTemplateData(marriageCard.master_template_card);
		setMarriageCardData(marriageCard);
		console.log(datas);
	};
	useEffect(() => {
		// const fetchData = async () => {
		// 	try {
		// 		const userData = await getById({ url: `marriage-card/${user}/invites/${invitedUser}` });
		// 		const { marriageCard, peopleInvited } = userData;
		// 		setUserData(marriageCard);
		// 		// console.log(marriageCard);
		// 		globalAct.setInvitedPeople(invitedUser);
		// 		globalAct.setBrideName(marriageCard.section_bride.brideSectionBrideName);
		// 		globalAct.setGroomName(marriageCard.section_groom.groomSectionGroomName);
		// 		globalAct.setPeopleInvitedData(peopleInvited);
		// 		setSubscriptionData(marriageCard.master_subscription_plan);
		// 		setTemplateData(marriageCard.master_template_card);
		// 		setMarriageCardData(marriageCard);
		// 	} catch (error) {
		// 		if (error.response && error.response.data && error.response.data.statusCode === 404) {
		// 			if (invitedUser !== 'Example') {
		// 				navigate('/404'); // Redirect to the 404 page
		// 			} else {
		// 				// console.log('Example user not found, continuing without redirect');
		// 				// Handle the case where invitedUser is 'Example' and 404 error occurs
		// 				// You can add custom logic here if needed
		// 			}
		// 		} else {
		// 			console.error('Error fetching');
		// 		}
		// 	}
		// };
		// fetchData();
		fetchDatas();
	}, [invitedUser, user, navigate, setSubscriptionData, setTemplateData, setMarriageCardData]);

	return (
		<div>
			{/* {userData && <TemplateOne />} */}
			{/* {userData && <TemplateTwo />} */}
			{/* {userData && <TemplateThree />} */}
			{userData && userData.master_template_card.id === template1 && <TemplateOne />}
			{userData && userData.master_template_card.id === template2 && <TemplateTwo />}
			{userData && userData.master_template_card.id === template3 && <TemplateThree />}
		</div>
	);
}

export default Invite;
