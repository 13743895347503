import { useState, createContext } from 'react';

import { SubscriptionProvider } from './subscription';
import { TemplateProvider } from './template';
import { MarrieageProvider } from './marriagedCard';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
	const [invitedPeople, setInvitedPeople] = useState('Example');
	const [brideName, setBrideName] = useState('Example');
	const [groomName, setGroomName] = useState('Example');
	const [showNavbarFooter, setShowNavbarFooter] = useState(false);
	const [peopleInvitedData, setPeopleInvitedData] = useState({});
	const [paket, setPaket] = useState(null);

	const contextValue = {
		globalCtx: {
			invitedPeople,
			brideName,
			groomName,
			showNavbarFooter,
			peopleInvitedData,
			paket,
		},
		globalAct: {
			setShowNavbarFooter,
			setInvitedPeople,
			setBrideName,
			setGroomName,
			setPeopleInvitedData,
			setPaket,
		},
	};

	return (
		<GlobalContext.Provider value={contextValue}>
			<SubscriptionProvider>
				<TemplateProvider>
					<MarrieageProvider>{children}</MarrieageProvider>
				</TemplateProvider>
			</SubscriptionProvider>
		</GlobalContext.Provider>
	);
};
