import { useState, createContext } from 'react';

export const TemplateContext = createContext();

export const TemplateProvider = ({ children }) => {
	const [templateData, setTemplateData] = useState();

	const contextValue = {
		templateData,
		setTemplateData,
	};

	return <TemplateContext.Provider value={contextValue}>{children}</TemplateContext.Provider>;
};
