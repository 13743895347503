import { useContext, useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

import { GlobalContext } from '../../config/context/global';
import { SubscriptionContext } from '../../config/context/subscription';
import { MarriageContext } from '../../config/context/marriagedCard';

// components
import Header from '../../components/template1/header';
import LoveStore from '../../components/template1/loveStore';
import Sosmed from '../../components/template1/sosmed';
import Location from '../../components/template1/location';
import Time from '../../components/template1/time';
import Gallery from '../../components/template1/gallery';
import Rfvp from '../../components/template1/rfvp';
import Footer from '../../components/template1/footer';
import LeftSection from '../../components/template1/leftSection';
import RightSection from '../../components/template1/rightSection';
import TransitionIntro from '../../components/template1/transition';
import DreessCode from '../../components/template1/dressCode';
import { GallerySlider } from '../../components/slider/gallerySlider';
import Gift from '../../components/template1/giftWedding';
import VideoWedding from '../../components/template1/video';

// utils
import { formatDate } from '../../utils/formatDate';

const textVariants = {
	hidden: { opacity: 0, y: 20 },
	visible: { opacity: 1, y: 0 },
};

function TemplateOne() {
	const audioRef = useRef(null);
	const navigate = useNavigate();
	const [isTransitionVisible, setIsTransitionVisible] = useState(true);
	const [showAllGallery, setShowAllGallery] = useState(false);
	const [showIntro, setShowIntro] = useState(true);
	const [isPlaying, setIsPlaying] = useState(false);
	const { globalCtx } = useContext(GlobalContext);
	const { subscriptionData } = useContext(SubscriptionContext);
	const { marriageCardData } = useContext(MarriageContext);
	const { section_cover, section_gallery, section_intro } = marriageCardData;

	const handleClose = () => setIsTransitionVisible(false);
	const handleAudioToggle = () => {
		if (audioRef.current) {
			if (isPlaying) {
				audioRef.current.pause();
			} else {
				audioRef.current.play();
			}
			setIsPlaying(!isPlaying);
		}
	};

	useEffect(() => {
		window.history.pushState({ page: 'uniquePageId' }, '', window.location.pathname);

		const handlePopState = (event) => {
			if (event.state && event.state.page === 'uniquePageId') {
				// Reload the page
				navigate('/');
				window.location.reload();
			}
		};

		// Attach the popstate event listener
		window.addEventListener('popstate', handlePopState);

		// Cleanup function to remove the event listener when the component unmounts
		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, [navigate]);

	return (
		<div className='flex flex-row'>
			{subscriptionData.isUsingIntroSection && (
				<AnimatePresence mode='wait'>
					{showIntro && <TransitionIntro setShowIntro={setShowIntro} />}
				</AnimatePresence>
			)}
			<audio
				ref={audioRef}
				src={marriageCardData.weddingMusic}
				loop
			/>
			<button
				onClick={handleAudioToggle}
				className='fixed z-20 bottom-3 left-1 md:bottom-5 md:left-5 text-white border-4 bg-transparent w-8 h-8 rounded-full flex items-center gap-2'
			>
				<FontAwesomeIcon
					icon={isPlaying ? faPause : faPlay}
					className='m-auto'
				/>
			</button>
			<div
				className={`${showAllGallery ? 'bg-black bg-opacity-50' : 'hidden'} z-40  fixed  w-full h-full`}
			>
				<GallerySlider
					setShowAllGallery={setShowAllGallery}
					showAllGallery={showAllGallery}
					galleries={section_gallery.gallerySectionGalleries}
				/>
			</div>
			<RightSection>
				<div
					className={`h-screen `}
					style={{
						backgroundColor: section_cover.coverSectionBackgroundColor,
						backgroundImage: section_intro.isIntroSectionUsingImage
							? `url(${section_intro.introSectionBackgroundImage})`
							: 'none',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				>
					<motion.div
						className=' w-full h-screen flex flex-col justify-end'
						initial='hidden'
						animate='visible'
						variants={textVariants}
						transition={{ duration: 0.6, ease: 'easeInOut' }}
					>
						<div
							className={`font-cormorant`}
							style={{ color: section_cover.coverSectionDescriptionColor }}
						>
							<motion.h3 className={`text-xl`}>
								{section_cover.coverSectionTitleText}
								<motion.p className='text-xl pb-5'>Our Wedding</motion.p>
							</motion.h3>
							<motion.h1
								className='text-7xl font-allura'
								style={{ color: section_cover.coverSectionTitleColor }}
							>
								{globalCtx.groomName} & {globalCtx.brideName}
							</motion.h1>
							<motion.p className='pb-16 text-xl'>
								{formatDate(marriageCardData.unixWeddingDate) || 'Wednesday / 21 / 08 / 2024'}
							</motion.p>
						</div>
					</motion.div>
				</div>
			</RightSection>
			<LeftSection
				isTransitionVisible={isTransitionVisible}
				isCover={subscriptionData.isUsingCoverSection}
			>
				<Header
					isTransitionVisible={isTransitionVisible}
					handleClose={handleClose}
					setIsPlaying={handleAudioToggle}
					setIsTransitionVisible={setIsTransitionVisible}
					isCover={subscriptionData.isUsingCoverSection}
				/>
				{subscriptionData.isUsingGroomSection && (
					<Sosmed
						sosmedName={marriageCardData.section_groom.groomSectionInstagramName}
						name={marriageCardData.section_groom.groomSectionGroomName}
						fatherName={marriageCardData.section_groom.groomSectionFamilyText}
						description={marriageCardData.section_groom.groomSectionDescriptionText}
						bgImage={marriageCardData.section_groom.groomSectionBackgroundImage}
						title={marriageCardData.section_groom.groomSectionTitleText}
						bgColor={marriageCardData.section_groom.groomSectionBackgroundColor}
						btnColor={marriageCardData.section_groom.groomSectionButtonColor}
						btnTextColor={marriageCardData.section_groom.groomSectionButtonTextColor}
						isUsingbgImage={marriageCardData.section_groom.isGroomSectionUsingImage}
						descriptionColor={marriageCardData.section_groom.groomSectionDescriptionColor}
						titleColor={marriageCardData.section_groom.groomSectionTitleColor}
					/>
				)}
				{subscriptionData.isUsingBrideSection && (
					<Sosmed
						sosmedName={marriageCardData.section_bride.brideSectionInstagramName}
						name={marriageCardData.section_bride.brideSectionBrideName}
						fatherName={marriageCardData.section_bride.brideSectionFamilyText}
						description={marriageCardData.section_bride.brideSectionDescriptionText}
						bgImage={marriageCardData.section_bride.brideSectionBackgroundImage}
						title={marriageCardData.section_bride.brideSectionTitleText}
						bgColor={marriageCardData.section_bride.brideSectionBackgroundColor}
						btnColor={marriageCardData.section_bride.brideSectionButtonColor}
						btnTextColor={marriageCardData.section_bride.brideSectionButtonTextColor}
						isUsingbgImage={marriageCardData.section_bride.isBrideSectionUsingImage}
						descriptionColor={marriageCardData.section_bride.brideSectionDescriptionColor}
						titleColor={marriageCardData.section_bride.brideSectionTitleColor}
					/>
				)}
				{subscriptionData.isUsingLoveStorySection && <LoveStore />}
				{subscriptionData.isUsingAgendaSection && <Location />}
				{subscriptionData.isUsingCountdownSection && <Time />}
				{subscriptionData.isUsingDressCodeSection && <DreessCode />}
				{/* {subscriptionData.isUsingGallerySection && <Gallery setShowAllGallery={setShowAllGallery} />} */}
				<Gallery setShowAllGallery={setShowAllGallery} />
				{subscriptionData.isUsingPreweddingVideo && <VideoWedding />}
				{subscriptionData.isUsingRSVPSection && <Rfvp />}
				{subscriptionData.isUsingDigitalWalletSection && <Gift />}
				<Footer />
			</LeftSection>
		</div>
	);
}

export default TemplateOne;
