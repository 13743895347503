import { useState, createContext } from 'react';

export const MarriageContext = createContext();

export const MarrieageProvider = ({ children }) => {
	const [marriageCardData, setMarriageCardData] = useState();

	const contextValue = {
		marriageCardData,
		setMarriageCardData,
	};

	return <MarriageContext.Provider value={contextValue}>{children}</MarriageContext.Provider>;
};
