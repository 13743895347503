import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { MarriageContext } from '../../config/context/marriagedCard';
import { GlobalContext } from '../../config/context/global';

const textVariants = {
	hidden: { opacity: 0, y: -20 },
	visible: { opacity: 1, y: 0 },
};

function Footer() {
	const { globalCtx } = useContext(GlobalContext);
	const { marriageCardData } = useContext(MarriageContext);
	const { section_footer } = marriageCardData;

	return (
		<div
			className='w-full h-screen'
			style={{
				backgroundColor: section_footer.footerSectionBackgroundColor,
				backgroundImage: section_footer.isFooterSectionUsingImage
					? `url(${section_footer.footerSectionBackgroundImage})`
					: 'none',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<div className='h-screen py-10 md:p-10 text-white flex flex-col justify-between'>
				<div className='text-white md:w-1/2 px-5 py-10 text-start'>
					<motion.h1
						className='text-6xl font-bold'
						style={{ color: section_footer.footerSectionTitleColor }}
						initial='hidden'
						animate='visible'
						transition={{ duration: 0.5, delay: 0.4 }}
						variants={textVariants}
					>
						{section_footer.footerSectionTitleText}
					</motion.h1>
					<motion.p
						className='text-md md:w-2/3 my-5 font-light'
						style={{ color: section_footer.footerSectionDescriptionColor }}
						initial='hidden'
						animate='visible'
						transition={{ duration: 0.5, delay: 0.4 }}
						variants={textVariants}
					>
						{section_footer.footerSectionDescription}
					</motion.p>
					<motion.p
						className='text-md font-bold'
						initial='hidden'
						animate='visible'
						transition={{ duration: 0.5, delay: 0.4 }}
						variants={textVariants}
					>
						#{globalCtx.groomName} & {globalCtx.brideName}
					</motion.p>
				</div>
			</div>
		</div>
	);
}

export default Footer;
