import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
	return (
		<div className='flex flex-col items-center justify-center h-screen text-center'>
			<h1 className='text-6xl font-bold mb-4'>404</h1>
			<p className='text-2xl mb-8'>Page Not Found</p>
			<Link
				to='/'
				className='px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-700 transition duration-300'
			>
				Go Home
			</Link>
		</div>
	);
};

export default PageNotFound;
