import api from '../lib/axios';

export const getAll = async ({ url = '/template-card/list', page, limit, token = '' }) => {
	try {
		const response = await api.get(url, {
			params: { page, limit },
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.data;
	} catch (error) {
		console.error('Error fetching template cards:', error);
		throw error;
	}
};

export const getById = async ({ url = '/template-card/detail', token = '' }) => {
	try {
		const response = await api.get(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.data.data;
	} catch (error) {
		console.error('Error fetching template cards:', error);
		throw error;
	}
};

export const postData = async ({ url = '/template-card/detail', token = '', data = {} }) => {
	try {
		const response = await api.post(url, data, {
			headers: {
				Authorization: token ? `Bearer ${token}` : '',
			},
		});

		return response.data;
	} catch (error) {
		console.error('Error fetching template cards:', error);
		if (error.response) {
			// Request made and server responded
			console.error('Response data:', error.response.data);
			console.error('Response status:', error.response.status);
			console.error('Response headers:', error.response.headers);
		} else if (error.request) {
			// Request made but no response received
			console.error('Request data:', error.request);
		} else {
			// Something happened in setting up the request
			console.error('Error message:', error.message);
		}
		throw error;
	}
};
