import { useEffect, useState, useRef, useContext } from 'react';
import { motion } from 'framer-motion';
import { MarriageContext } from '../../config/context/marriagedCard';

const textVariants = {
	hidden: { opacity: 0, y: -20 },
	visible: { opacity: 1, y: 0 },
};

const circleVariants = {
	hidden: { opacity: 0, scale: 0.8 },
	visible: { opacity: 1, scale: 1 },
};

function DressCode() {
	const ref = useRef(null);
	const { marriageCardData } = useContext(MarriageContext);
	const [isInView, setIsInView] = useState(false);
	const { section_dresscode } = marriageCardData;
	useEffect(() => {
		const currentRef = ref.current;
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsInView(true);
					observer.disconnect(); // Stop observing after the first intersection
				}
			},
			{
				rootMargin: '0px',
				threshold: 0.1,
			}
		);

		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, []);

	return (
		<div
			className='w-full h-screen'
			style={{
				backgroundColor: section_dresscode.dressCodeSectionBackgroundColor,
				backgroundImage: section_dresscode.isDressCodeSectionUsingImage
					? `url(${section_dresscode.dressCodeSectionBackgroundImage})`
					: 'none',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				scrollSnapAlign: 'center',
			}}
		>
			<div
				ref={ref}
				className='h-screen p-10 text-center flex flex-col justify-center'
			>
				<div
					className='w-full h-fit'
					style={{ color: section_dresscode.dressCodeSectionDescriptionColor }}
				>
					<motion.h1
						className='font-bold text-4xl my-5'
						style={{ color: section_dresscode.dressCodeSectionTitleColor }}
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 0.5, delay: 0.4 }}
						variants={textVariants}
					>
						{section_dresscode.dressCodeSectionTitleText}
					</motion.h1>
					{/* <motion.p
						className='text-sm text-center px-10'
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 0.5, delay: 0.6 }}
						variants={textVariants}
					>
						{marriageCardData.dressCodeSectionDescription}
					</motion.p> */}
					<motion.div
						className='flex justify-center mt-8 space-x-5'
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 0.5, delay: 0.8 }}
						variants={textVariants}
					>
						{section_dresscode?.dressCodeSectionColors &&
							section_dresscode.dressCodeSectionColors?.map((color, index) => {
								return (
									<motion.div
										key={index}
										className='w-10 h-10 rounded-full'
										style={{ backgroundColor: color }}
										initial='hidden'
										animate={isInView ? 'visible' : 'hidden'}
										transition={{ duration: 0.8, delay: index * 0.8 }}
										variants={circleVariants}
									/>
								);
							})}
					</motion.div>
				</div>
			</div>
		</div>
	);
}

export default DressCode;
