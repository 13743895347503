import axios from 'axios';

// console.log(process.env.REACT_APP_URI);
const api = axios.create({
	baseURL: process.env.REACT_APP_URI,
	headers: {
		'Content-Type': 'application/json',
	},
});

export default api;
