import React from 'react';

function Btn({ title, colorBg, colorText, className, ...props }) {
	return (
		<button
			type='button'
			className={`relative ${colorBg} ${colorText} ${className} text-sm md:text-md inline-block focus:outline-none focus:ring-2 focus:ring-white focus:ring-inset-2 focus:ring-inset-gray-800 rounded-md`}
			{...props}
		>
			{title}
		</button>
	);
}

export default Btn;
