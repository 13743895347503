import React from 'react';
import './btnScaleAnimation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

const AnimatedIcon = () => {
	return (
		<button className='up-down bg-transparent rounded-full flex flex-col items-center justify-center text-white py-2 px-4'>
			<FontAwesomeIcon
				icon={faChevronUp}
				className='text-4xl'
			/>
			SWIPE UP
		</button>
	);
};

export default AnimatedIcon;
