import CardTestimonial from '../../components/card/cardTestimonial';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination } from 'swiper/modules';
import SwiperCore from 'swiper/core';

// Initialize Swiper modules
SwiperCore.use([Navigation, Pagination]);

// Define custom navigation buttons as React components
const PrevButton = ({ onClick }) => (
	<button
		onClick={onClick}
		className='hidden custom-prev-button bg-[#B07C92] w-12 h-12 text-white md:flex items-center justify-center rounded-full absolute top-1/2 transform -translate-y-1/2 -left-7 z-10'
	>
		<svg
			width='24'
			height='25'
			viewBox='0 0 24 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M20 11.4453H7.83L13.42 5.85531L12 4.44531L4 12.4453L12 20.4453L13.41 19.0353L7.83 13.4453H20V11.4453Z'
				fill='#3A2414'
			/>
		</svg>
	</button>
);

const NextButton = ({ onClick }) => (
	<button
		onClick={onClick}
		className='hidden custom-next-button bg-[#B07C92] w-12 h-12 text-white md:flex items-center justify-center rounded-full absolute top-1/2 transform -translate-y-1/2 -right-7 z-10'
	>
		<svg
			width='24'
			height='25'
			viewBox='0 0 24 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M12 4.44531L10.59 5.85531L16.17 11.4453H4V13.4453H16.17L10.59 19.0353L12 20.4453L20 12.4453L12 4.44531Z'
				fill='#3A2414'
			/>
		</svg>
	</button>
);

const testimonialData = [
	{
		image: '/t-avt-1.png',
		name: 'Anne Smith',
		position: 'Position, Company name',
		title:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum expedita odit beatae, cupiditate saepe quam officia aut placeat quas neque!',
	},
	{
		image: '/t-avt-2.png',
		name: 'Jane Doe',
		position: 'Position, Company name',
		title:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum expedita odit beatae, cupiditate saepe quam officia aut placeat quas neque!',
	},
	{
		image: '/t-avt-3.png',
		name: 'John Doe',
		position: 'Position, Company name',
		title:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum expedita odit beatae, cupiditate saepe quam officia aut placeat quas neque!',
	},
	{
		image: '/t-avt-3.png',
		name: 'John Doe',
		position: 'Position, Company name',
		title:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum expedita odit beatae, cupiditate saepe quam officia aut placeat quas neque!',
	},
	{
		image: '/t-avt-3.png',
		name: 'John Doe',
		position: 'Position, Company name',
		title:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum expedita odit beatae, cupiditate saepe quam officia aut placeat quas neque!',
	},
];

function Testimonial() {
	const pagination = {
		clickable: true,
		renderBullet: function (index, className) {
			return `<span class="${className} bg-black "></span>`;
		},
	};
	return (
		<div className='section text-[#3A2414]'>
			<div className='flex flex-col'>
				<h1 className='text-5xl font-bold mb-5'>Customer testimonials</h1>
				<p className='text-lg'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
			</div>
			<div className='container relative '>
				{/* Swiper component */}
				<Swiper
					navigation={{
						prevEl: '.custom-prev-button',
						nextEl: '.custom-next-button',
					}}
					spaceBetween={15}
					slidesPerView={3}
					pagination={pagination}
					loop={true}
					breakpoints={{
						320: {
							slidesPerView: 1,
							spaceBetween: 10,
						},
						640: {
							slidesPerView: 2,
							spaceBetween: 10,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 15,
						},
					}}
					className=''
				>
					{testimonialData.map((person, index) => (
						<SwiperSlide key={index}>
							<div className='flex items-center justify-center my-3'>
								{/* Avatar, name, position */}
								<CardTestimonial
									title={person.title}
									name={person.name}
									position={person.position}
								/>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
				{/* Custom navigation buttons */}
				<PrevButton />
				<NextButton />
			</div>
		</div>
	);
}

export default Testimonial;
