import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Btn({ title, colorBg, colorText, className, icon, ...props }) {
	return (
		<button
			type='button'
			className={`${colorBg} ${colorText} ${className} text-sm md:text-md inline-block focus:outline-none focus:ring-2 focus:ring-white focus:ring-inset-2 focus:ring-inset-gray-800 rounded-md`}
			{...props}
		>
			{icon && (
				<span className='mr-1'>
					<FontAwesomeIcon icon={icon} />
				</span>
			)}
			{title}
		</button>
	);
}

export default Btn;
