import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Btn from '../../components/button/btn';
import CardProject from '../../components/card/cardProject';

import { MarriageContext } from '../../config/context/marriagedCard';

function Portofolio() {
	const navigate = useNavigate();
	const { marriageCardData } = useContext(MarriageContext);
	return (
		<div className='section text-[#3A2414] section-gap'>
			<div className='flex flex-col w-fit mx-auto'>
				<h2 className='text-4xl font-bold  md:w-[616px] md:mx-auto'>Berbagai Pilihan Desain</h2>
			</div>
			<div className='flex flex-col gap-5'>
				<div className='mx-auto md:mx-0 md:flex items-center justify-center gap-8 '>
					{marriageCardData.length > 0 &&
						marriageCardData.slice(0, 3).map((project, i) => (
							<CardProject
								key={i}
								name={project.master_template_card.name}
								link={`/invitation/${project.master_user.username}/to/Example`}
								image={project.thumbnailBackgroundImage}
							/>
						))}
					{/* <CardProject
						name={'Project name here'}
						link={'www.example.com'}
						image={'assets/images/porto2.png'}
						width={'300px'}
					/>
					<CardProject
						name={'Project name here'}
						link={'www.example.com'}
						image={'assets/images/porto3.png'}
						width={'300px'}
					/> */}
				</div>
				<div className=''>
					<Btn
						colorBg={'bg-[#B07C92]'}
						title={'View All'}
						colorText={'text-white'}
						className={'px-6 py-3 '}
						onClick={() => navigate('/desain')}
					/>
				</div>
			</div>
		</div>
	);
}

export default Portofolio;
