import WhatsappButton from '../components/button/btnWa';
import ContactUs from '../layout/contactUs';

function Contact() {
	return (
		<>
			<ContactUs />
			<WhatsappButton
				phoneNumber={'+6282144346975'}
				message={
					'Halo Lovephoria Invitation! Saya ingin tahu lebih banyak tentang layanan undangan pernikahan digital Anda.'
				}
			/>
		</>
	);
}

export default Contact;
