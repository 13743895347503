import { Link } from 'react-router-dom';

function CardProject({ name, link, image }) {
	return (
		<div
			className={` w-[320px] max-h-[560px] border my-5 md:my-0 border-[#3A2414] text-[#3A2414] rounded-lg  flex justify-center flex-col items-center`}
		>
			<div className='rounded-lg w-full'>
				<img
					src={image}
					alt='project'
					width={'100%'}
					className='rounded-t-lg object-cover max-h-[320px]'
				/>
			</div>
			<div className='w-full p-4 flex flex-col gap-6'>
				<h3 className='font-bold text-start text-xl'>{name}</h3>
				<Link
					to={link}
					className='flex text-sm text-[#B07C92] text-start '
				>
					View Project
					<span className='p-1.5 px-3'>
						<svg
							width='8'
							height='12'
							viewBox='0 0 8 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M1.70697 11.9496L7.41397 6.24264L1.70697 0.535645L0.292969 1.94964L4.58597 6.24264L0.292969 10.5356L1.70697 11.9496Z'
								fill='#B07C92'
							/>
						</svg>
					</span>
				</Link>
			</div>
		</div>
	);
}

export default CardProject;
