import Carousel from '../slider/slider';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { MarriageContext } from '../../config/context/marriagedCard';
import { GlobalContext } from '../../config/context/global';

const textVariants = {
	hidden: { opacity: 0, y: -20 },
	visible: { opacity: 1, y: 0 },
};
const scaleVariants = {
	hidden: { scale: 0.5, opacity: 0 },
	visible: { scale: 1, opacity: 1 },
};

function Gallery() {
	const ref = useRef(null);
	const [isInView, setIsInView] = useState(false);
	const { marriageCardData } = useContext(MarriageContext);
	const { globalCtx } = useContext(GlobalContext);
	const { section_gallery, section_prewedding } = marriageCardData;

	useEffect(() => {
		const currentRef = ref.current;
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsInView(true);
					observer.disconnect(); // Stop observing after the first intersection
				}
			},
			{
				rootMargin: '0px',
				threshold: 0.1,
			}
		);

		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, []);
	console.log(section_prewedding.preweddingSectionVideo);

	return (
		<div
			ref={ref}
			className='h-screen'
			style={{
				backgroundColor: section_gallery.gallerySectionBackgroundColor,
				backgroundImage: section_gallery.isGallerySectionUsingImage
					? `url(${section_gallery.gallerySectionBackgroundImage})`
					: 'none',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				scrollSnapAlign: 'center',
			}}
		>
			<div className='flex flex-col md:flex-row h-screen text-white items-center md:px-20 p-4 justify-center gap-2 md:gap-10'>
				<div
					className={`${
						section_prewedding.preweddingSectionVideo ? '' : 'hidden'
					} md:w-2/3 w-11/12 pt-1 flex justify-center`}
				>
					<motion.div
						className='w-full h-full flex items-center justify-center'
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 2, delay: 0.4 }}
						variants={scaleVariants}
					>
						<video
							controls
							className='w-full h-full rounded-xl object-cover'
							loop
							autoPlay
							muted
						>
							<source
								src={section_prewedding.preweddingSectionVideo}
								type='video/mp4'
							/>
							Your browser does not support the video tag.
						</video>
					</motion.div>
				</div>
				<motion.div
					className={`text-white text-start ${
						section_prewedding.preweddingSectionVideo ? 'md:w-1/2' : 'md:w-full'
					} w-full h-full flex flex-col items-start`}
				>
					<motion.h1
						className='text-5xl font-bold md:mt-12'
						style={{ color: section_gallery.gallerySectionTitleColor }}
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 2, delay: 0.4 }}
						variants={textVariants}
					>
						{section_gallery.gallerySectionTitleText}
					</motion.h1>
					<motion.p
						className='mt-4 md:my-5 text-sm md:text-base'
						style={{ color: section_gallery.gallerySectionDescripitonColor }}
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 2, delay: 0.6 }}
						variants={textVariants}
					>
						{section_gallery.gallerySectionDescription}
					</motion.p>
					<motion.p
						className='mb-4 md:my-3 text-lg my-5 font-bold md:text-base'
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 2, delay: 0.8 }}
						variants={textVariants}
					>
						Photo {globalCtx.groomName} & {globalCtx.brideName}
					</motion.p>
					<div className={`w-full ${section_prewedding.preweddingSectionVideo ? '' : 'my-16'}`}>
						<Carousel
							dataImages={section_gallery.gallerySectionGalleries}
							slide={section_prewedding.preweddingSectionVideo ? 3 : 5}
						/>
					</div>
				</motion.div>
			</div>
		</div>
	);
}

export default Gallery;
