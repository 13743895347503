import React from 'react';
import './btnScaleAnimation.css'; // Pastikan jalur ke file CSS benar

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';

const AnimatedButton = ({ title, onClick }) => {
	return (
		<button
			className='infinite-scale bg-[#EEC373] rounded-full text-[#353535] py-1 px-4'
			onClick={onClick}
		>
			<FontAwesomeIcon icon={faEnvelopeOpen} /> {title}
		</button>
	);
};

export default AnimatedButton;
