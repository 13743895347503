import './App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { GlobalContext } from './config/context/global';

//components
import Navbar from './layout/navbar';
import Footer from './layout/footer';
import Router from './config/routes';
import ScrollToTop from './utils/scrollTop';

const validPaths = [
	'/',
	'/beranda',
	'/tentang-kami',
	'/desain',
	'/harga',
	'/privacy-and-policy',
	'/term-conditions',
	'/hubungi-kami',
	'/invitation/:user?/:invitedUse?',
];

function App() {
	const navigate = useNavigate();
	const location = useLocation();
	const { globalAct, globalCtx } = useContext(GlobalContext);

	// Check if the current path matches the invitation path
	const isInvitationPath = /^\/invitation(\/[^/]+\/[^/]+)?$/.test(location.pathname);
	useEffect(() => {
		if (!validPaths.includes(location.pathname)) {
			globalAct.setShowNavbarFooter(true);
		} else {
			globalAct.setShowNavbarFooter(false);
		}
	}, [location.pathname, globalAct]);

	return (
		<div>
			<ScrollToTop />
			{!isInvitationPath && !globalCtx.showNavbarFooter && <Navbar navigate={navigate} />}
			<Router />
			{!isInvitationPath && !globalCtx.showNavbarFooter && <Footer />}
		</div>
	);
}

export default App;
