import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { GlobalContext } from '../../config/context/global';
import Btn from '../../components/button/btn';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

const navigation = [
	{ name: 'Beranda', to: '/', current: true },
	// { name: 'Tentang Kami', to: '/tentang-kami', current: false },
	{ name: 'Desain', to: '/desain', current: false },
	// { name: 'Harga', to: '/harga', current: false },
];
export default function Example({ navigate }) {
	const location = useLocation();
	const { globalAct } = useContext(GlobalContext);
	return (
		<Disclosure
			as='nav'
			className='bg-[#3A2414] fixed w-full z-50'
		>
			{({ open }) => (
				<>
					<div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
						<div className='relative  flex h-16 items-center justify-start md:justify-between'>
							<div className='absolute inset-y-0 right-0 flex items-center sm:hidden'>
								{/* Mobile menu button*/}
								<DisclosureButton className='relative inline-flex items-center justify-center rounded-md p-2 text-white'>
									<span className='absolute -inset-0.5' />
									<span className='sr-only'>Open main menu</span>
									{open ? (
										<XMarkIcon
											className='block h-6 w-6'
											aria-hidden='true'
										/>
									) : (
										<Bars3Icon
											className='block h-6 w-6'
											aria-hidden='true'
										/>
									)}
								</DisclosureButton>
							</div>
							<div className='flex flex-1 items-center justify-between pe-14 md:pe-0 sm:items-stretch sm:justify-between'>
								<div className='w-[18vh]'>
									<Link to='/'>
										<img
											src='assets/images/logo.png'
											alt='logo'
										/>
									</Link>
								</div>
								<div className='hidden sm:flex '>
									<div className='flex space-x-4 items-center'>
										{navigation.map((item, index) => {
											const isActive = location.pathname === item.to;
											return (
												<Link
													key={index}
													to={item.to}
													className={classNames(
														isActive ? 'text-[#B07C92]' : 'text-gray-300  hover:text-[#B07C92]',
														'rounded-md px-3 py-2 text-md font-medium'
													)}
													aria-current={isActive ? 'page' : undefined}
												>
													{item.name}
												</Link>
											);
										})}
									</div>
								</div>
								<div className='flex flex-shrink-0 items-center'>
									<Btn
										title={'Hubungi Kami'}
										colorText={'text-white'}
										colorBg={'bg-[#B07C92]'}
										className={'py-[1.5vh] px-[2vh] text-[2.5vh]'}
										onClick={() => {
											globalAct.setPaket(null);
											navigate('/hubungi-kami');
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					{/* Mobile */}
					<DisclosurePanel className='sm:hidden'>
						<div className='space-y-1 px-2 pb-3 pt-2'>
							{navigation.map((item, index) => {
								const isActive = location.pathname === item.to;

								return (
									<Link
										to={item.to}
										key={index}
									>
										<DisclosureButton
											key={index}
											as='a'
											// href={item.href}
											className={classNames(
												isActive ? ' text-[#B07C92]' : 'text-gray-300 hover:text-[#B07C92]',
												'block rounded-md px-3 py-2 text-base font-medium'
											)}
											aria-current={isActive ? 'page' : undefined}
										>
											{item.name}
										</DisclosureButton>
									</Link>
								);
							})}
						</div>
					</DisclosurePanel>
				</>
			)}
		</Disclosure>
	);
}
