import React from 'react';
import { useNavigate } from 'react-router-dom';
import Btn from '../../components/button/btn';
// import images from '../../assets/images/example.png';

function Header() {
	const navigate = useNavigate();
	return (
		<div
			className='h-fit pt-16 md:pt-0'
			style={{
				backgroundImage: 'url(/assets/images/headbg1.jpg',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundBlendMode: 'overlay', // Optional: helps blend the gradient with the image
			}}
		>
			<div className='flex relative flex-col md:flex-col lg:flex-row items-center bg-[#3A241480] py-10 md:section h-full lg:h-screen w-full gap-5 '>
				<div className='flex flex-col text-white gap-6 text-start w-full lg:w-1/2'>
					<h1 className='text-3xl px-5 md:text-5xl  md:px-0 font-bold space-x-14-y-9 '>
						Buat Undangan Pernikahan Digital Anda yang Elegan
					</h1>
					<p className='text-md px-5 md:px-0 md:mt-4'>
						Undangan Pernikahan Digital yang Elegan dan Personal
					</p>
					<div className='flex  w-full justify-start items-center gap-5 px-5	md:px-0'>
						<Btn
							title={'Mulai Desain Sekarang'}
							colorText={'text-white'}
							colorBg={'bg-[#B07C92]'}
							className={'px-5 py-3 border border-[#B07C92]'}
						/>
						<Btn
							title={'Lihat Koleksi'}
							colorText={'text-white'}
							colorBg={'bg-transparent'}
							className={'border border-white px-5 py-3'}
							onClick={() => navigate('/desain')}
						/>
					</div>
				</div>
				<div className='flex justify-center mt-5 h-screen  lg:w-1/2 lg:mt-10 lg:h-[80vh]'>
					<img
						src='/assets/images/headerbg.png'
						alt='services'
						className='object-contain '
					/>
				</div>
			</div>
		</div>
	);
}

export default Header;
