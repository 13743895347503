import React, { useEffect, useState } from 'react';

const DropdownInput = ({ title = 'Choose a topic:', value, className = '', onChange, style }) => {
	const [selectedOption, setSelectedOption] = useState(value || 'Option 1');

	useEffect(() => {
		setSelectedOption(value);
	}, [value]);

	const handleSelectChange = (event) => {
		const newValue = event.target.value;
		setSelectedOption(newValue);
		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<div className='flex flex-col h-fit text-start my-3'>
			{title && (
				<label
					htmlFor='dropdown'
					className='font-medium'
				>
					{title}
				</label>
			)}
			<select
				id='dropdown'
				value={selectedOption}
				onChange={handleSelectChange}
				className={`${className} py-3 px-2`}
				style={style}
			>
				<option
					value='yes'
					className='bg-gray text-black text-lg '
				>
					Hadir
				</option>
				<option
					value='no'
					className='bg-gray text-black text-lg '
				>
					Tidak Hadir
				</option>
				<option
					value='maybe'
					className='bg-gray text-black text-lg '
				>
					Mungkin
				</option>
			</select>
		</div>
	);
};

export default DropdownInput;
