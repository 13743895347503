import React, { useEffect, useState } from 'react';

const DropdownInputVisitor = ({ title, value, className, onChange }) => {
	const [selectedOption, setSelectedOption] = useState(value || '1');

	useEffect(() => {
		setSelectedOption(value);
	}, [value]);

	const handleSelectChange = (event) => {
		const newValue = event.target.value;
		setSelectedOption(newValue);
		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<div className='flex flex-col h-fit text-start my-3'>
			{title && (
				<label
					htmlFor='dropdown'
					className='font-medium'
				>
					{title}
				</label>
			)}
			<select
				id='dropdown'
				value={selectedOption}
				onChange={handleSelectChange}
				className={`${className} py-3 px-2 border rounded-lg border-white bg-[#00000036] text-slate-50 placeholder-slate-300 focus:outline-none  focus:ring-white focus:border-white`}
			>
				<option
					value={0}
					className='bg-gray text-black text-lg '
				>
					-
				</option>
				<option
					value={1}
					className='bg-gray text-black text-lg '
				>
					1 orang
				</option>
				<option
					value={2}
					className='bg-gray text-black text-lg '
				>
					2 orang
				</option>
			</select>
		</div>
	);
};

export default DropdownInputVisitor;
