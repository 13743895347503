import { useEffect, useRef, useState, useCallback } from 'react';
import { motion } from 'framer-motion';

const imageOneVariants = {
	hidden: { opacity: 0, x: -20 },
	visible: { opacity: 1, x: 0 },
};

const imageTwoVariants = {
	hidden: { opacity: 0, x: 20 },
	visible: { opacity: 1, x: 0 },
};

function Gallery({ galleryData }) {
	const refs = useRef([]);
	const [isInView, setIsInView] = useState([]);

	const addToRefs = useCallback((el) => {
		if (el && !refs.current.includes(el)) {
			refs.current.push(el);
		}
	}, []);

	useEffect(() => {
		const currentRefs = refs.current;
		const observers = currentRefs.map((ref, index) => {
			const observer = new IntersectionObserver(
				([entry]) => {
					setIsInView((prev) => {
						const newInView = [...prev];
						newInView[index] = entry.intersectionRatio >= 0.3;
						return newInView;
					});
				},
				{ threshold: 0.3 }
			);

			if (ref) {
				observer.observe(ref);
			}

			return observer;
		});

		return () => {
			observers.forEach((observer, index) => {
				if (currentRefs[index]) {
					observer.unobserve(currentRefs[index]);
				}
			});
		};
	}, []);

	const leftData = galleryData.filter((_, index) => index % 2 === 0);
	const rightData = galleryData.filter((_, index) => index % 2 !== 0);

	return (
		<div className='w-full h-full bg-white py-5 snap-center'>
			<h1 className='text-3xl font-bold'>GALLERY</h1>
			<div className='flex gap-2 justify-center mx-auto w-full '>
				<div className='flex-col w-full'>
					{leftData.map((value, index) => (
						<motion.div
							ref={addToRefs}
							key={index}
							className='w-full h-fit my-2'
							initial='hidden'
							animate={isInView[index] ? 'visible' : 'hidden'}
							transition={{ duration: 1.5, delay: 0.4 }}
							variants={imageOneVariants}
						>
							<img
								src={value}
								alt={`Gallery ${index + 1}`}
								className='w-full object-cover'
							/>
						</motion.div>
					))}
				</div>
				<div className='flex-col w-full'>
					{rightData.map((value, index) => (
						<motion.div
							ref={addToRefs}
							key={index} // Adjust key to avoid duplicates
							className='w-full h-fit  my-2'
							initial='hidden'
							animate={isInView[index + leftData.length] ? 'visible' : 'hidden'}
							transition={{ duration: 1.5, delay: 0.4 }}
							variants={imageTwoVariants}
						>
							<img
								src={value}
								alt={`Gallery ${index + leftData.length + 1}`}
								className='w-full h-fit object-cover'
							/>
						</motion.div>
					))}
				</div>
			</div>
		</div>
	);
}

export default Gallery;
