import React from 'react';
// import image from '../../assets/images/example.png';

function Service() {
	return (
		<div className='flex section relative'>
			<div className='lg:flex mx-auto justify-between w-full gap-10'>
				<div className='lg:w-1/2 flex flex-col items-start justify-center gap-8 text-[#624A2D] '>
					<div className=' md:px-0 flex flex-col gap-6 md:w-full lg:w-full'>
						<h2 className='text-4xl text-start font-bold '>Mengapa Memilih Kami?</h2>
						{/* <p className='text-start '>
							At Love Phoria, we understand that every wedding is a unique expression of love and style.
							Our exquisite digital wedding invitation cards are meticulously crafted to embody
							sophistication and charm, offering a seamless blend of timeless elegance and modern
							aesthetics. Whether you prefer classic designs with intricate details or contemporary styles
							that reflect your personal flair, our collection promises to elevate your celebration with
							unmatched quality and refinement.
						</p> */}
					</div>
					<div className=' lg:w-full'>
						<ul className='list-disc mx-5 md:mx-10 text-start flex flex-col gap-4'>
							<li className=''>
								<b>Desain Mewah:</b> Pilih dari berbagai desain premium yang memukau.
							</li>
							<li className=''>
								<b>Personalisasi Mudah:</b> Sesuaikan undangan Anda dengan mudah sesuai keinginan.
							</li>
							<li className=''>
								<b>Kirim Secara Digital:</b> Bagikan undangan Anda melalui email atau media sosial.
							</li>
							<li className=''>
								<b>Ramah Lingkungan:</b> Kurangi penggunaan kertas dengan undangan digital yang
								berkelanjutan.
							</li>
						</ul>
					</div>
				</div>
				<div className='flex justify-center mt-5 md:my-5 lg:w-1/2 lg:mt-0 lg:justify-end lg:h-[80vh]'>
					<img
						src='assets/images/header.jpg'
						alt='services'
						width='100%'
					/>
				</div>
			</div>
		</div>
	);
}

export default Service;
