import Btn from '../button/btn';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { getById, postData } from '../../api';
import { MarriageContext } from '../../config/context/marriagedCard';
import { GlobalContext } from '../../config/context/global';
import Swal from 'sweetalert2';

function Rfvp() {
	const ref = useRef(null);
	const { marriageCardData } = useContext(MarriageContext);
	const { globalCtx } = useContext(GlobalContext);
	const [isInView, setIsInView] = useState(false);
	const [activeButton, setActiveButton] = useState('Tulis Ucapan');
	const [showForm, setShowForm] = useState(true);
	const [selectedRSVP, setSelectedRSVP] = useState('');
	const [guestMessage, setGuestMessage] = useState([]);
	const [formMessage, setFormMessage] = useState({
		RSVP: '',
		name: globalCtx.peopleInvitedData.attendantName,
		message: '',
		visitorTotal: 1,
		peopleInvitedId: globalCtx.peopleInvitedData.id,
		marriageCardId: marriageCardData.id,
	});
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormMessage((prevFormMessage) => ({
			...prevFormMessage,
			[name]: value,
		}));
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await postData({ url: `messages/add`, data: formMessage });
			if (response.statusCode === 201) {
				// Reset the form
				setFormMessage({
					name: '',
					message: '',
					RSVP: 'yes',
					visitorTotal: 1,
					peopleInvitedId: globalCtx.peopleInvitedData.id,
					marriageCardId: globalCtx.peopleInvitedData.marriageCardId,
				});
				setSelectedRSVP('false');
				// Refresh the messages
				const messageData = await getById({ url: `messages/list/message/${marriageCardData.id}` });
				setGuestMessage(messageData);
			}
		} catch (error) {
			Swal.fire({
				title: 'Sorry!',
				text:
					error.response.data.statusCode === 400 &&
					'Ini adalah undangan sampel. Pengguna tidak dapat menambahkan pesan RSVP.',
				icon: 'error',
				confirmButtonText: 'OK',
			});
			setFormMessage({
				name: globalCtx.peopleInvitedData.name,
				message: '',
				RSVP: 'yes',
				visitorTotal: '',
				peopleInvitedId: globalCtx.peopleInvitedData.id,
				marriageCardId: globalCtx.peopleInvitedData.marriageCardId,
			});
			console.error('Error submitting message:');
		}
	};

	useEffect(() => {
		const fetchMessage = async () => {
			try {
				const messageData = await getById({ url: `messages/list/message/${marriageCardData.id}` });
				setGuestMessage(messageData);
			} catch (error) {
				console.error('Error fetching message data:', error);
			}
		};
		fetchMessage();

		const currentRef = ref.current;
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsInView(true);
					observer.disconnect();
				}
			},
			{
				rootMargin: '0px',
				threshold: 0.1,
			}
		);

		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, [marriageCardData]);

	const handleGuestList = (buttonType) => {
		setShowForm(!showForm);
		setShowForm(buttonType === 'Tulis Ucapan');
		setActiveButton(buttonType);
	};
	const handleRSVPChange = (value) => {
		setSelectedRSVP(value);
		setFormMessage((prevFormMessage) => ({
			...prevFormMessage,
			RSVP: value,
		}));
	};
	return (
		<div
			ref={ref}
			className='w-full h-screen p-10 relative'
		>
			<motion.h1
				className='text-center text-4xl'
				initial='hidden'
				animate={isInView ? 'visible' : 'hidden'}
				transition={{ duration: 1, delay: 0.4 }}
				variants={{ hidden: { opacity: 0, y: -20 }, visible: { opacity: 1, y: 0 } }}
			>
				Doa & Ucapan
			</motion.h1>

			{/* Tombol "Tulis Ucapan" dan "Buku Tamu" ditampilkan di sini */}
			<div className='flex justify-center mt-5'>
				<Btn
					title={'Tulis Ucapan'}
					colorText={activeButton === 'Tulis Ucapan' ? 'text-white' : 'text-black'}
					className={`text-lg p-2 border ${
						activeButton === 'Tulis Ucapan' ? 'bg-gray-500' : 'bg-white'
					} mr-2`}
					onClick={() => handleGuestList('Tulis Ucapan')}
				/>
				<Btn
					title={'Buku Tamu'}
					colorText={activeButton === 'Buku Tamu' ? 'text-white' : 'text-black'}
					className={`text-lg p-2 border ${
						activeButton === 'Buku Tamu' ? 'bg-gray-500' : 'bg-white'
					} mr-2`}
					onClick={() => handleGuestList('Buku Tamu')}
				/>
			</div>

			{/* Logika render form atau daftar tamu */}
			{showForm ? (
				<div className='flex flex-col my-5 justify-center md:px-36'>
					{/* Form ucapan */}
					<form onSubmit={handleSubmit}>
						<div className='text-start my-5'>
							<div className='flex items-center my-3'>
								{[
									{ title: 'Tidak Hadir', value: 'no' },
									{ title: 'Hadir', value: 'yes' },
									{ title: 'Ragu', value: 'maybe' },
								].map((option, i) => (
									<label
										key={i}
										className='flex items-center cursor-pointer'
										onClick={() => handleRSVPChange(option.value)}
									>
										<input
											type='checkbox'
											name='kehadiran'
											value={option.title}
											checked={selectedRSVP === option.value}
											className='hidden peer'
											readOnly
										/>
										<span
											className={`mr-2 rounded-full w-4 h-4 flex items-center justify-center border border-gray-400 ${
												selectedRSVP === option.value ? 'bg-blue-500' : 'bg-gray-300'
											}`}
										>
											{selectedRSVP === option.value && (
												<svg
													className='w-3 h-3 text-white pointer-events-none'
													viewBox='0 0 20 20'
												>
													<polyline
														points='3 10 8 15 17 6'
														fill='none'
														stroke='currentColor'
														strokeWidth='2'
													/>
												</svg>
											)}
										</span>
										<span className='text-gray-700 mr-4'>{option.title}</span>
									</label>
								))}
								{/* Tambahkan checkbox lainnya di sini */}
							</div>
							<div className=''>
								<input
									type='text'
									name='name'
									value={formMessage.name}
									onChange={handleInputChange}
									placeholder='Nama'
									className='my-2 p-3 bg-white rounded-md shadow-sm border-slate-400 placeholder-slate-400 focus:outline-none focus:border-sky-300 focus:ring-sky-300 block w-full sm:text-sm focus:ring-1 focus:rounded-md'
								/>
							</div>
							<textarea
								id='message'
								name='message'
								rows='5'
								value={formMessage.message}
								onChange={handleInputChange}
								className='resize-none border rounded-md w-full p-3 border-gray leading-tight focus:outline-none focus:border-sky-300 focus:ring-sky-300 block sm:text-sm focus:ring-1 focus:rounded-md'
								placeholder='Ucapan'
							/>
						</div>
						<div className=''>
							<Btn
								title={'Kirim'}
								colorBg={'bg-white'}
								colorText={'text-black'}
								className={'hover:bg-gray-500 py-3 w-full'}
								type='submit'
							/>
						</div>
					</form>
					<hr className='h-0.5 bg-slate-500 my-2' />
				</div>
			) : (
				// Jika showForm false, tampilkan daftar tamu
				<div className='flex flex-col my-10 justify-center md:px-36 h-[70vh]'>
					<ul className='divide-y divide-gray-300 text-center w-full mx-auto bg-white overflow-auto'>
						{guestMessage.map((guest) => {
							let kehadiran;
							switch (guest.RSVP) {
								case 'yes':
									kehadiran = 'Hadir';
									break;
								case 'no':
									kehadiran = 'Tidak hadir';
									break;
								default:
									kehadiran = 'Ragu';
									break;
							}
							return (
								<li
									key={guest.id}
									className='py-2'
								>
									<h1 className='font-bold text-xl'>{guest.PeopleInvited.attendantName}</h1>
									<p className='w-1/2 mx-auto text-lg my-3'>{guest.message}</p>
									<p className='text-xl'>-{kehadiran}-</p>
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
}

export default Rfvp;
